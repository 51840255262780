import { type ReactNode, useEffect, useState } from "react";

import { cookieNames, readCookie } from "@/utils/storage.ts";

export const AuthorizedOnly = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (readCookie(cookieNames.sessionActive) === "true") {
      setShow(true);
    }
  }, []);

  if (!show) {
    return null;
  }

  return <>{children}</>;
};
