import { Fragment, type ReactNode } from "react";

import { AuthorizedOnly } from "@/components/AuthorizedOnly.tsx";
import { UnauthorizedOnly } from "@/components/UnauthorizedOnly.tsx";

type MenuItemProps = {
  authorizedOnly?: boolean;
  unauthorizedOnly?: boolean;
  children: ReactNode;
};

export const MenuItem = ({
  authorizedOnly = false,
  unauthorizedOnly = false,
  children,
}: MenuItemProps) => {
  let Component:
    | typeof Fragment
    | typeof AuthorizedOnly
    | typeof UnauthorizedOnly = Fragment;

  if (authorizedOnly) {
    Component = AuthorizedOnly;
  }

  if (unauthorizedOnly) {
    Component = UnauthorizedOnly;
  }

  return <Component>{children}</Component>;
};
