import { type ReactNode, useEffect, useState } from "react";

import { cookieNames, readCookie } from "@/utils/storage.ts";

export const UnauthorizedOnly = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (readCookie(cookieNames.sessionActive) === "true") {
      setShow(false);
    }
  }, []);

  if (!show) {
    return null;
  }

  return <>{children}</>;
};
